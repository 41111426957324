@import "~common_styles/main";
@import "~bootstrap/scss/bootstrap";
html {
  height: 100%;
  font-size: 40%;
  @include large-phone-mode {
    font-size: 40.5%;
  }
  @include tablet-mode {
    font-size: 43.5%;
  }
  @include desktop-mode {
    font-size: 45.5%;
  }
  @include large-desktop-mode {
    font-size: 50.5%;
  }
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-display: auto;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f0f0;
}

.white-back {
  background-color: #ffffff !important;
}

#root {
  height: 100%;
}

code {
  font-display: auto;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.main-content {
  height: 100%;
  // position: relative;
  // top: #{$menu-height};
  width: 100%;
  // padding-top: $menu-height;
  // margin-top: $menu-height + 0.7rem;
  padding-left: $left-navigation-width;
  transition: 0.4s width;
  &--collapsed {
    padding-left: $left-navigation-width-expanded;
  }
  &--full-width {
    padding-left: 0;
  }
}
.CF__Background {
  background-color: $color_pale_while;
  padding: 0 3.2rem;
}
.text-cf-dark {
  color: $color_ebony;
}
//global css
.vertically-center-align {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.vert-hor-center-align {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vert-center-align {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
a {
  color: black;
}
a:hover {
  text-decoration: none;
  color: black;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no_scroll {
  overflow: hidden;
}
.shift_up {
  margin-top: -4px;
}

@media print {
  .page-break {
    page-break-before: always;
    page-break-after: always;
    display: block;
  }
}
.Toastify__toast-body {
  font-size: 2rem;
}
.hidden {
  display: none;
  transition: 4s all;
}
.visually-hidden {
  opacity: 0;
}
/* .ellipsice {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
} */
