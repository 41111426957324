@import "utils/breakpoints.scss";
@import "~theme/colors.scss";
@import "~theme/filepaths.scss";
@import "utils/variables.scss";
@import "utils/utils.scss";
@import url("//fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
@import url("//fonts.googleapis.com/icon?family=Material+Icons");

// Font Sizes used

.fs-nav-item {
  font-size: 1.7rem;
}

.fs-page-title {
  font-size: 18px;
}

.fs-page-title-height {
  height: 60px;
}

.fs-tab-title {
  font-size: 16px;
}

.fs-detail-title {
  font-size: 16px;
}

.fs-card-label {
  font-size: 16px;
}

.fs-card-supscript {
  font-size: 14px;
}

.fs-card-key {
  font-size: 14px;
}

.fs-card-value {
  font-size: 16px;
}

.ag-theme-balham {
  font-size: 14px !important;
}

.ag-theme-balham .ag-header {
  font-size: 14px !important;
}

.chkfake-btn-yellow {
  padding: 6px 10px;
  border-radius: 4px;
  border: solid 1px #fdaf20;
  background-color: #fdaf20;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  color: #111526;
  cursor: pointer;
  font-weight: 400;
}

.chkfake-btn-white {
  padding: 6px 10px;
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  background-color: #ffffff;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  color: #111526;
  cursor: pointer;
  font-weight: 400;
}

.blue-text {
  color: #227ea1;
}

.overflow-text {
  max-width: 95% !important;
  padding-right: 3px;
  line-height: 1em !important;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mt-40 {
  margin-top: 2.5rem;
}

.mt-80 {
  margin-top: 5rem;
}
